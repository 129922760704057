<template>
  <div class="signin" :class="layout">
    <div class="kb-login login" id="kb">
      <router-view/>
    </div>
    <LxpPopup />
  </div>
</template>
<script>
import LxpPopup from '@/components/common/LxpPopup';
import navigationUtils from "@/assets/js/navigationUtils";

export default {
  name: 'AuthLayout',
  components: {LxpPopup},
  setup() {
    const isMobile = navigationUtils.any();

    const layout = isMobile ? 'lxp-mobile-layout' : 'lxp-layout';

    return {
      layout,
      isMobile
    }
  }
};
</script>